body {
  margin: 0;
  font-family: "ingra-condensed", sans-serif;
  font-weight: 500;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0e4d4;
  color: #172558;
  padding: 2em;
  transition: 0.7s ease-in-out;
}

h1 {
  font-family: "ingra", sans-serif;
  font-weight: 400;
  font-style: normal;
}

h2 {
  font-family: "ingra", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.content {
  max-width: 900px;
  margin: 0 auto;
}

nav {
  margin: 1em 0 5em;
}

h2 {
  margin-top: 5em;
}

.ps {
  margin-bottom: 2em;
  gap: 2.5em;
  display: flex;
}

p {
  margin: 0;
  margin-top: 1.5em !important;
  font-size: 16px;
  line-height: 1.4em;
  letter-spacing: 0.01em;
  width: 60%;
}

nav::before,
.ps::before,
h2::before,
.info p::before,
.cms p::before,
.canali::before,
video::before,
h1::before {
  margin-left: 8px;
  margin-right: 8px;
}

.swiper {
}

nav {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

a {
  color: unset;
}

a:hover {
  text-decoration: underline;
}

h2,
h1 {
  text-transform: uppercase;
  color: #ffffff;
}

h1 {
  font-size: 46px;
  letter-spacing: 5px;
}

h2 {
  font-size: 22px;
  letter-spacing: 2px;
}

.ps p:nth-child(2),
.opa {
  opacity: 0.5;
}

img,
video {
  width: 100%;
}

.logo {
  width: 12em;
}

/* swiper */

.swiper-pagination-total {
  color: rgb(136, 136, 136) !important;
}

.swiper-button-prev {
  width: 100px !important;
  background-image: url("../public/assets/arrowBack.svg") !important;
  background-repeat: no-repeat !important;
  opacity: 0.5;
}

.swiper-button-next {
  width: 100px !important;
  background-image: url("../public/assets/arrowNext.svg") !important;
  background-repeat: no-repeat !important;
  opacity: 0.5;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
  opacity: 1;
}

.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after,
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  content: unset !important;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  content: unset !important;
  opacity: 0.5;
}

.swiper-pagination {
  margin: 0;
  width: auto;

  margin-top: 0.5em;
  /* float: right;
  text-align: right; */
}

/* 
.mySwiperP {
  position: relative;
  top: -2.7em;
} */

h5 {
  margin-top: 0.7em !important;
  margin-bottom: 0;
}

.expo h2 {
  margin-top: 2.5em;
}

.canali {
  display: flex;
  flex-direction: column;
}

.canali h2 {
  margin-left: 0;
}

.canali a {
  text-decoration-color: #ffffff;
}

.social a {
  margin: 0.3em 0;
}

.info a {
  text-decoration: none;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-bottom: 5em;
}

.footer img {
  width: 75px;
}

/* .gradient {
  position: absolute;
  width: 100%;
  right: 0;
  bottom: 0;
  background-image: url("../public/assets/gradient.svg") no-repet !important;
} */

@media only screen and (max-width: 700px) {
  body {
    padding: 1em;
  }

  p,
  a,
  .swiper-pagination {
    font-size: 18px;
  }

  h1 {
    font-size: 36px;
  }

  h2 {
    font-size: 24px;
  }

  .ps {
    display: block;
  }
  p {
    width: 100%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none !important;
  }

  .mySwiperP {
    position: relative;
    top: -3.1em;
  }

  video {
    margin: 0;
  }
}

@media only screen and (min-width: 1000px) {
  .logo {
    width: 14em;
  }

  .content {
    max-width: 1200px;
    margin: 0 auto;
  }

  p,
  a,
  .swiper-pagination {
    font-size: 20px;
  }

  h1 {
    font-size: 58px;
  }

  h2 {
    font-size: 28px;
  }
}
